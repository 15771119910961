<template>
  <span>
    {{ text }}
  </span>
</template>

<script lang="ts">
import { translate, translateByKeySilent } from '~/core/ts/util/translate'
import { capitalizeFirstWord, capitalizeAllWords, capitalizeAllLargeWords } from '~/core/ts/util/string'

export default defineNuxtComponent({
  props: {
    byKey: {
      type: String,
      default: () => '',
    },
    byRaw: {
      type: Object,
      default: () => null,
    },
    keyForRaw: {
      type: String,
      default: () => '',
    },
    defaultLabel: {
      type: String,
      default: () => 'NOTEXT',
    },
    capitalizeFirst: {
      type: Boolean,
      default: false
    },
    capitalizeAllLarge: {
      type: Boolean,
      default: false
    },
    capitalizeAll: {
      type: Boolean,
      default: false
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    plural: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object as PropType<Record<string, string | number>>,
      default: () => ({})
    },
    trimStart: {
      type: Boolean,
      default: false
    },
    trimEnd: {
      type: Boolean,
      default: false
    },
    trim: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    let text = ''

    if (props.byKey) {
      text = translateByKeySilent(props.byKey, props.plural) || props.defaultLabel
    } else if (props.byRaw) {
      text = props.keyForRaw
        ? translate(props.byRaw)[props.keyForRaw] || props.defaultLabel
        : translate(props.byRaw)
    }

    Object.entries(props.params).forEach(([key, value]) => {
      text = text.replaceAll(`{{${key}}}`, value + '')
    })

    if (props.capitalizeFirst) {
      text = capitalizeFirstWord(text)
    }

    if (props.capitalizeAllLarge) {
      text = capitalizeAllLargeWords(text)
    }

    if (props.capitalizeAll) {
      text = capitalizeAllWords(text)
    }

    if (props.uppercase) {
      text = text.toUpperCase()
    }

    if (props.trimStart) {
      text = text.trimStart()
    }
    if (props.trimEnd) {
      text = text.trimEnd()
    }
    if (props.trim) {
      text = text.trim()
    }

    return {
      text,
    }
  }
})
</script>
